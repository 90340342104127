<template>
  <v-card
    :loading="loading"
    class="mx-auto my-6 pt-2 rounded-xl grey darken-4"
    width="400">

    <v-card-title
      class="justify-center mb-2 primaryText--text">
        Register with
    </v-card-title>

    <v-card-text>
      <v-row no-gutters justify="center">
        <v-col>
        </v-col>
        <v-col class="d-flex align-center justify-center mr-2">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-lg lineBox py-5 px-10"
                dark
                v-bind="attrs" v-on="on">
                  <v-icon color="primaryText">mdi-facebook</v-icon>
              </v-btn>
            </template>
            <span class="pb-16">Facebook Join</span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex align-center justify-center mr-2">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-lg lineBox py-5 px-10"
                dark
                v-bind="attrs" v-on="on">
                  <v-icon color="primaryText">mdi-google-plus</v-icon>
              </v-btn>
            </template>
            <span class="pb-16">Google Join</span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-lg lineBox py-5 px-10"
                dark
                v-bind="attrs" v-on="on">
                  <v-icon color="primaryText">mdi-apple</v-icon>
              </v-btn>
            </template>
            <span class="pb-16">Apple Join</span>
          </v-tooltip>
        </v-col>
        <v-col>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-subtitle
      class="pa-1 justify-center text-subtitle-2 primaryText--text">
      <span>or</span>
    </v-card-subtitle>

    <v-card-text class="mt-3 px-6 justify-center">
      <v-text-field
        v-model="email"
        @update:error="chkSum(0)"
        dense outlined
        :rules="[rules.required, rules.email]"
        counter maxlength="30"
        label="Email">
      </v-text-field>
      <v-text-field
        v-model="name"
        @update:error="chkSum(1)"
        dense outlined
        :rules="[rules.required, rules.counter]"
        counter maxlength="20"
        label="Name">
      </v-text-field>
      <v-text-field
        v-model="password"
        @update:error="chkSum(2)"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="show ? 'text' : 'password'"
        label="Password"
        hint="At least 8 characters"
        counter dense outlined
        @click:append="show = !show">
      </v-text-field>
      <v-checkbox
        class="ma-0 pa-0 text-body-2"
        label="I agree the Terms and Conditions"
        color="#9e9e9e"
        v-model="chk[3]"
        hide-details dense>
      </v-checkbox>
      <v-btn
        class="mt-4"
        :disabled="chk[0] || chk[1] || chk[2] || !chk[3]"
        @click="setMemberData"
        color="primary" width="100%">
        Sign&nbsp;Up
      </v-btn>
    </v-card-text>

    <div
      class="justify-center text-subtitle-2 primaryText--text pb-4">
      <span class="mr-1">Already have an account?</span>
      <v-btn
        class="text-subtitle-2"
        color="fourthText lighten-2"
        @click="goRoute('/acc/login', 'Login')"
        plain>
          Sign&nbsp;In
      </v-btn>
    </div>

  </v-card>
</template>

<script>
import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});
Vue.use(VueCryptojs)

export default {
  name: 'Join',

  components: {

  },

  props: {

  },

  created: function () {
    this.$emit('setAppTitle', this.appTitle)
    this.setTitle()
  },

  mounted: function () {

  },

  methods: {
    chkSum: function(value) {
      this.chk[value] = false
    },
    addMember: function() {
      instance
        .post('addMember', {email: this.email, password: this.ecpText, name: this.name, salt: this.salt})
        .then(response => ( this.result(response.data) ))
        .catch(error => (this.err = error))
    },
    result: function(res) {
      this.err = res
      this.goRoute('/acc/login', 'Login')
    },
    setMemberData: function() {
      this.ecpText = this.$CryptoJS.AES.encrypt(this.password, this.salt).toString()
      this.addMember()
      //this.dcpText = this.$CryptoJS.AES.decrypt(this.ecpText, "poke").toString(this.CryptoJS.enc.Utf8)
    },
    setTitle: function() {
      this.$emit('setMainTitle', this.mainTitle)
      this.$emit('setSubTitle', this.subTitle)
    },
    goRoute: function(route, value) {
      this.$router.push({
        path: route,
        name: value,
      })
    },
  },

  data: () => ({
    appTitle: 'JoinAccount',
    mainTitle: 'Welcome!',
    subTitle: 'Sign up to enjoy a lot of free content!',
    chk: [true, true, true, false,],
    salt: 'today',
    err: '',
    email: '',
    name: '',
    password: '',
    ecpText: '',
    loading: false,
    show: false,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      min: v => v.length >= 8 || 'Min 8 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
  }),
};
</script>

<style>
  .lineBox { border: 1px solid #9e9e9e; }
  .mdi-eye-off::before {color: #4b82f2 !important;}
  .mdi-eye::before {color: #4b82f2 !important;}
</style>
