<template>
  <v-row v-if="starComp != null">
    <v-col
      v-for="(lists, i) in starComp" :key="i">
      <v-card
        :to="{path: '/cat/stardetail', name: 'StarDetail',
          query: {pk: lists.avActor_Pk, page: page} }"
        class="mx-auto text-center grey darken-4 primaryText--text"
        width="80" height="140" link>
        <v-img
          class="mb-1 white--text align-end"
          :src="lists.actorImg"
          lazy-src="../assets/images/unKnownStar.png"
          height="80px" contain position="center">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primaryText">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <span class="text-caption font-weight-bold">
          {{lists.enName}}
        </span>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ActorListView',

  props: {
    starComp: Array,
    page: Number,
  },

  components: {

  },

  methods: {

  },

  data: () => ({

  }),
};
</script>
