<template>
  <v-stepper
    class="rounded-lg"
    color="red"
    elevation="0"
    v-model="e1">
    <v-stepper-header
      class="grey darken-4 rounded-lg mt-1 white--text">
      <template v-for="n in steps">
        <v-stepper-step
          :key="`${n}-step`"
          :complete="e1 > n"
          :step="n"
          editable>
             <span v-if="n==1">Product Insert</span>
             <span v-else-if="n==2">Star Insert</span>
             <span v-else-if="n==3">Connect</span>
             <span v-else-if="n==4">Video Path</span>
        </v-stepper-step>

        <v-divider
          v-if="n !== steps"
          :key="n">
        </v-divider>
      </template>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        class=""
        v-for="n in steps"
        :key="`${n}-content`"
        :step="n">

        <!-- 스텝 내용 -->
        <div v-if="n==1"><product/></div>
        <div v-else-if="n==2"><actor/></div>
        <div v-else-if="n==3"><connvideotostar/></div>
        <div v-else-if="n==4"><videopath/></div>

        <div class="mt-10">
          <v-btn
            color="primary"
            @click="nextStep(n)">
            Next
          </v-btn>

          <v-btn text>
            Previous
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import product from '../components/AddProductComp.vue';
import actor from '../components/AddActorComp.vue';
import videopath from '../components/AddVideoPathComp.vue';
import connvideotostar from '../components/ConnVideoToStarComp.vue';

export default {
  name: 'ProductAdd',

  components: {
    product,
    actor,
    videopath,
    connvideotostar,
  },

  props: {

  },

  created: function () {
    this.$emit('setAppTitle', this.appTitle)

  },

  mounted: function () {

  },

  methods: {
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
    nextStep: function(n) {
      if (n === this.steps) this.e1 = 1
      else this.e1 = n + 1
    },
  },

  data: () => ({
    appTitle: 'ProductAdd',
    e1: 1,
    steps: 4,
  }),
};
</script>
<style>
  .theme--dark.v-stepper{ background-color: #121212 !important; }
  .theme--dark.v-icon{ color: white !important; }
</style>
