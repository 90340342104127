<template>

    <v-card
      class="mx-auto grey darken-4 rounded-lg">
      <v-card-title class="ml-3 pt-7 primaryText--text text-h4 font-weight-black mt-4">
        Insert Video Path
      </v-card-title>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Video Path
      </v-card-title>

      <v-text-field
        class="mx-6"
        dense
        color="thirdText"
        background-color="grey darken-3"
        v-model="pathData"
        :rules="[rules.required]"
        @change="chkForm"
        outlined dark
        label="VideoPath">
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Select a video to add a path
      </v-card-title>

      <v-select
        v-model="stdItem"
        :items="colList"
        :rules="[rules.required]"
        @change="chkForm"
        label="VideoList"
        class="mx-6 select"
        item-color="secondaryText"
        background-color="grey darken-3"
        return-object
        dense dark outlined>
      </v-select>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-lg mr-4 mb-4"
          color="secondaryText"
          :loading="loading"
          :disabled="btnDisabled"
          @click="setVideoPath()"
          dark>
          <v-icon v-if="btnDisabled" small>mdi-pencil-off-outline</v-icon>
          <v-icon v-else small>mdi-pencil-plus-outline</v-icon>
          <span class="mx-1">Submit</span>
        </v-btn>
      </v-card-actions>
      
    </v-card>

</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'VideoConn',

  components: {

  },

  props: {
    comTitle: String,
  },

  created: function () {
    this.getColList('avPd_Pk')
  },

  methods: {
    getColList: function (colName) {
      instance
        .post('getColList', {col: colName})
        .then(response => (this.colList = response.data))
        .catch(error => (this.err = error))
    },
    setVideoPath: function () {
      this.btnLoading = true

      instance
        .post('addVideoPath', {videoPk: this.stdItem, videoPath: this.pathData})
        .then(response => (this.resultReflect(response)))
        .catch(error => (this.err = error))
    },
    chkForm: function () {
      if(this.stdItem != null && this.pathData != null){
        this.btnDisabled = false
      }
    },
    resultReflect: function (res) {
      this.subRes = res.data
      this.btnLoading = false
      this.btnDisabled = true
    },
  },

  data: () => ({
    err: null,
    colList: null,
    btnLoading: false,
    btnDisabled: true,
    loading: false,
    stdItem: null,
    pathData: null,
    subRes: null,
    rules: {
        required: value => !!value || '필수 입력 항목입니다.',
    },
  }),
};
</script>

<style>
.theme--light.v-list{
  background: #424242;
}
.v-list-item__content{
  color: #fff;
}
.select {
  max-width: 220px;
}
</style>
