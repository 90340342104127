<template>
  <div>
    <vAppBar
      @chgDrawer = chgDrawer()
      @setSearchField = getSearch
      :appBarTitle = "appTitle" />

    <v-card class="mt-3 rounded-lg grey darken-4">
      <v-tabs
        v-model="tab"
        background-color="fourthText"
        color="white"
        dark>
        <v-tab
          v-for="item in items"
          :to="item.path"
          :key="item.tab">

          {{ item.tab }}

        </v-tab>
      </v-tabs>

      <router-view :sField="searchField"></router-view>

    </v-card>

  </div>
</template>

<script>
import vAppBar from '../components/CustomBar.vue';

export default {
  name: 'Manage',

  components: {
    vAppBar,
  },

  props: {
    comTitle: String,
  },

  created: function () {
    localStorage.clear()
  },

  methods: {
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
    getSearch: function(value) {
      this.searchField = value
    },
  },

  data: () => ({
    appTitle : 'Manage',
    tab: null,
    searchField: '',
    items: [
        { tab: 'avProductContents', path: '/manage/product', content: 'Tab 1 Content' },
        { tab: 'avActor', path: '/manage/actor', content: 'Tab 2 Content' },
        { tab: 'avVideo', path: '/manage/videopath', content: 'Tab 3 Content' },
        { tab: 'account', path: '/manage/account', content: 'Tab 4 Content' },
      ],
  }),
};
</script>
