<template>
  <div class="">

    <v-data-table
      class="elevation-1 primaryText--text"
      :headers="headers" :items="lists"
      :page.sync="page" :items-per-page="itemsPerPage"
      :search="sField"
      hide-default-footer fixed-header
      dark
      @page-count="pageCount = $event">

      <template v-slot:item.videoPath="props">
        <v-edit-dialog
          dark
          :return-value.sync="props.item.videoPath"
          large persistent
          @save="save(props.item.videoPath, props.item.avVideo_Pk)"
          @cancel="cancel" @open="open" @close="close">

          <div>{{props.item.videoPath}}</div>

          <template v-slot:input>
            <div class="mt-4 text-Subtitle-2 font-weight-black primaryText--text">Data change (videoPath)</div>
            <div class="text-button primaryText--text"> {{props.item.avVideo_Pk}}_{{props.item.videoPath}} </div>
            <v-text-field
              v-model="props.item.videoPath"
              label="Edit"
              single-line counter autofocus>
            </v-text-field>
          </template>

        </v-edit-dialog>
      </template>

    </v-data-table>

    <div class="text-center py-2 white--text">
      <v-pagination
        v-model="page"
        color="green" dark
        :length="pageCount" :total-visible="8">
      </v-pagination>
    </div>

    <v-snackbar
       v-model="snack"
       :timeout="2000"
       :color="snackColor">

       {{ snackText }}

       <template v-slot:action="{ attrs }">
         <v-btn
           v-bind="attrs"
           text
           @click="snack = false">
           Close
         </v-btn>
       </template>
     </v-snackbar>

  </div>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'VideoPath',

  components: {

  },

  props: {
    comTitle: String,
    sField: String,
  },

  created: function () {
    instance
      .get('getTotVideoPathList')
      .then(response => (this.lists = response.data))
      .catch(error => (this.err = error))
  },

  methods: {
    save (col, cont, pathPk) {

      instance
        .post('setActorContents', {content: cont, pk: pathPk})
        .then(response => ( this.success(response.data) ))
        .catch(error => ( this.failed(error) ))

    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close () {
      console.log('Dialog closed')
    },
    success (res){
      if(Number(res) == 1){
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'DataBase Update Success'
      }
      else this.failed
    },
    failed () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'DataBase Update Failed'
    },
    setMini: function() {
      this.$emit('setVariant')
    }
  },

  data: () => ({
    page: 1,
    pageCount: 15,
    itemsPerPage: 13,
    snack: false,
    snackColor: '',
    snackText: '',
    err: null,
    headers: [
      { text: '비디오경로코드', value: 'avVideo_Pk', sortable: true, width: '100', class: 'primaryText--text'},
      { text: '비디오경로', value: 'videoPath', width: '300', class: 'primaryText--text'},
    ],
    lists: [],
  }),
};
</script>

<style>
  .text{ height:80px; text-overflow:ellipsis; word-wrap:break-word;
          display: -webkit-box; -webkit-line-clamp:3; -webkit-box-orient:vertical;
          overflow:hidden }
  .v-pagination__item:not(.v-pagination__item--active) {
    background: #4f4f4f !important;
  }
  .v-pagination__navigation {
    background: #4f4f4f !important;
  }
</style>
