<template>
  <div>
    <img ref="img_blob" src="">
  </div>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'Cartoon',

  components: {

  },

  props: {

  },

  created: function () {
    this.$emit('setAppTitle', this.appTitle)
    localStorage.clear()

  },

  mounted: function () {
    this.getImgEncode()

    this.canvas = document.createElement("canvas")
    //let ctx = this.canvas.getContext("2d")
    //let oriImage = this.$refs.img_ori

    //let blobImage = this.$refs.img_blob
    //this.canvas.width = oriImage.width
    //this.canvas.height = oriImage.height
    //ctx.drawImage(oriImage, 0, 0, oriImage.width, oriImage.height)

    //this.canvas.toBlob(function(blob) {
      //blobImage.src = URL.createObjectURL(blob)
    //})

  },

  methods: {
    cvImgEncode: function (res) {
      let baseUrl = 'data:image/png;base64,' + res

      let tmp = new Image()
      tmp.src = baseUrl

      let tmp2 = this.$refs.img_blob

      let canvas = document.createElement("canvas")
      let context = canvas.getContext("2d")

      tmp.onload = function() {
        canvas.width = this.width
        canvas.height = this.height
        context.drawImage(tmp, 0, 0)
        canvas.toBlob(function(blob) {
          let url = URL.createObjectURL(blob)
          tmp2.src = url
          tmp2.onload = function() { URL.revokeObjectURL(url) }
        })
      }

    },
    getImgEncode: function (){
      instance
        .post('cvImgEncode', {url: 'https://neistar.synology.me/img/caribbeancom/012121-001.jpg'})
        .then(response => ( this.cvImgEncode(response.data) ))
        .catch(error => (this.err = error))
    },
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
  },

  data: () => ({
    appTitle: 'Cartoon',
  }),
};
</script>
