import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from '../views/HomeView.vue'
import VideoView from '../views/VideoView.vue'
import StarView from '../views/StarView.vue'
import StarDetailView from '../views/StarDetailView.vue'
import CartoonView from '../views/CartoonView.vue'
import WebtoonView from '../views/WebtoonView.vue'
import WebtoonDetailView from '../views/WebtoonDetailView.vue'
import ProfileView from '../views/ProfileView.vue'
import AccessDeniedView from '../views/AccessDeniedView.vue'
import AdminComp from '../components/AdminComp.vue'
import ActorComp from '../components/ActorComp.vue'
import AccountComp from '../components/AccountComp.vue'
import VideoPathComp from '../components/VideoPathComp.vue'
import LookResultComp from '../components/LookResult.vue'
import ManageView from '../views/ManageView.vue'
import PlayView from '../views/PlayView.vue'
import CategoryView from '../views/CategoryView.vue'
import AccountView from '../views/AccountView.vue'
import JoinView from '../views/JoinView.vue'
import LoginView from '../views/LoginView.vue'
import PruductAddView from '../views/PruductAddView.vue'

Vue.use(VueRouter);

import VueJwtDecode from 'vue-jwt-decode'

const adminAuth = () => (to, from, next) => {

  if(sessionStorage.getItem('token') != null){
    let token = VueJwtDecode.decode(sessionStorage.getItem('token'))

    if (token[0].member_rating == 'MASTER') return next()
    else next('/cat/accessdenied')

  }
  else next('/cat/accessdenied')

};

const invitationAuth = () => (to, from, next) => {
  if(sessionStorage.getItem('token') != null){
    let token = VueJwtDecode.decode(sessionStorage.getItem('token'))

    if (token[0].member_rating == 'MASTER' || token[0].member_rating == 'DIAMOND') return next()
    else next('/cat/accessdenied')

  }
  else next('/cat/accessdenied')
};

const requireAuth = () => (to, from, next) => {
  if (sessionStorage.getItem('token') != null) {
    return next()
  }
  next('/cat/accessdenied')
};

const rebirthAuth = () => (to, from, next) => {
  if (sessionStorage.getItem('token')) {
    return next('/')
  }
  next()
};

// Vue Router를 관리하는 객체
export const router = new VueRouter({
  mode: 'history',

  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  routes: [
    {
      // path: url 주소
      path: '/',
      // component: url로 갔을 때 표시될 컴포넌트
      name: 'Home',
      component: HomeView,
      props: true,
    },
    {
      path: '/manage',
      name: 'Manage',
      redirect: '/manage/product',
      component: ManageView,
      props: true,
      children: [
        {
          path: "product",
          name: 'Product',
          props: true,
          component: AdminComp,
          beforeEnter: adminAuth(),
        },
        {
          path: "actor",
          name: 'Actor',
          props: true,
          component: ActorComp,
          beforeEnter: adminAuth(),
        },
        {
          path: "videopath",
          name: 'VideoPath',
          props: true,
          component: VideoPathComp,
          beforeEnter: adminAuth(),
        },
        {
          path: "account",
          name: 'Account',
          props: true,
          component: AccountComp,
          beforeEnter: adminAuth(),
        },
      ],
    },
    {
      path: '/cat',
      name: 'Category',
      component: CategoryView,
      props: true,
      children: [
        {
          path: "lookresult",
          name: 'LookResultComp',
          props: true,
          component: LookResultComp,
          beforeEnter: invitationAuth(),
        },
        {
          path: 'video',
          name: 'Video',
          component: VideoView,
          props: true,
          beforeEnter: invitationAuth(),
        },
        {
          path: 'star',
          name: 'Star',
          component: StarView,
          props: true,
          beforeEnter: invitationAuth(),
        },
        {
          path: 'stardetail',
          name: 'StarDetail',
          component: StarDetailView,
          props: true,
          beforeEnter: invitationAuth(),
        },
        {
          path: 'cartoon',
          name: 'Cartoon',
          component: CartoonView,
          props: true,
        },
        {
          path: 'webtoon',
          name: 'Webtoon',
          component: WebtoonView,
          props: true,
        },
        {
          path: 'webtoondetail',
          name: 'WebtoonDetail',
          component: WebtoonDetailView,
          props: true,
        },
        {
          path: 'play',
          name: 'Play',
          component: PlayView,
          props: true,
          beforeEnter: invitationAuth(),
        },
        {
          path: 'accessdenied',
          name: 'AccessDenied',
          component: AccessDeniedView,
          props: true,
        },
        {
          path: 'profile',
          name: 'Profile',
          component: ProfileView,
          props: true,
          beforeEnter: requireAuth(),
        },
        {
          path: 'productAdd',
          name: 'ProductAdd',
          component: PruductAddView,
          props: true,
          beforeEnter: adminAuth(),
        },
      ],
    },
    {
      path: '/acc',
      name: 'AccountView',
      component: AccountView,
      props: true,
      children: [
        {
          path: 'join',
          name: 'Join',
          component: JoinView,
          props: true,
          beforeEnter: rebirthAuth(),
        },
        {
          path: 'login',
          name: 'Login',
          component: LoginView,
          props: true,
          beforeEnter: rebirthAuth(),
        },
      ],
    },
  ]
})
