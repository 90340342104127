<template>
  <div>

    <vAppBar
      @chgDrawer = chgDrawer()
      @setSearchField = getSearchTxt
      :appBarTitle = "appTitle" />

    <router-view
      class="mt-2"
      ref="callChild"
      :key="$route.fullPath"
      :sField="searchField"
      @setAppTitle = "setTitle">
      </router-view>

  </div>
</template>

<script>
import vAppBar from '../components/CustomBar.vue';

export default {
  name: 'Category',

  components: {
    vAppBar,
  },

  props: {
    comTitle: String,
  },

  created: function () {

  },

  methods: {
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
    getSearchTxt: function(value) {
      this.searchField = value
      if(this.searchField=='' || this.searchField == null)
        this.$refs.callChild.returnSearch()
      else
        this.$refs.callChild.goSearch()
    },
    setTitle: function(value) {
      this.appTitle = value
    },
  },

  data: () => ({
    appTitle : '',
    searchField: '',
  }),
};
</script>
