<template>
  <div class="mt-3" ref="view">

    <v-row>
      <v-col>
        <v-tabs
          v-model="tab"
          class="rounded-lg"
          background-color="grey darken-4"
          show-arrows dark>
          <v-tab
            class="font-weight-black"
            @click="setSelectedTag(lists.tags, i)"
            v-for="(lists, i) in tagList"
            :key="i">
            {{lists.tags}}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <videoView :videoComp="vList" :page="page"
      :stdTag="stdTag" :tab="tab" :key="componentKey"/>

    <v-row>
      <v-col>
        <v-pagination
          v-model="page"
          color="green"
          :length="pageCount"
          :total-visible="9"
          @input="chgPage" dark>
        </v-pagination>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import videoView from '../components/VideoListComp.vue';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'Video',

  components: {
    videoView,
  },

  props: {
    comTitle: String,
  },

  created: function () {
    if(this.$route.query.page == null) this.page = 1
    else this.page = Number(this.$route.query.page)

    if(this.$route.query.tab == null) this.tab = 0
    else this.tab = Number(this.$route.query.tab)

    if(this.$route.query.tag == null) this.stdTag = ''
    else this.stdTag = this.$route.query.tag

    this.getPageCount()
    this.getTagList()
    this.getVideoList()

    this.$emit('setAppTitle', this.appTitle)
  },

  mounted: function () {

  },

  beforeUpdate: function () {

  },

  methods: {
    getVideoList: function (){

      if(this.stdTag == 'all') this.stdTag = ''

      instance
        .post('getList', {range: this.rangeList, page: this.page, tag: this.stdTag})
        .then(response => ( this.vList = response.data ))
        .catch(error => (this.err = error))

    },
    setBaseImg: function (res){
      this.vList = res
      this.imgList = []

      this.forceRerender()

      this.vList.forEach((item, i) => {
        this.imgList.push('')
        this.getBaseImg(item.avThumbnail, i)
      })

    },
    getPageCount: function (){
      if(this.stdTag == 'all') this.stdTag = ''

      instance
        .post('getCnt', {range: this.rangeList, tag: this.stdTag})
        .then(response => (this.pageCount = response.data))
        .catch(error => (this.err = error))
    },
    getTagList: function (){
      instance
        .get('getVideoTagList')
        .then(response => ( this.setTagList(response) ))
        .catch(error => (this.err = error))
    },
    setTagList: function (res) {
      this.tagList = res.data
      this.tagList.unshift({ "tags": "all" })
    },
    setSelectedTag: function (tag, tab) {
      this.stdTag = tag
      this.tab = tab
      this.chgPage(1)
    },
    goSearch: function() {
      this.$router.push({
        path: '/cat/lookresult',
        name: 'LookResultComp',
        query: { tabnum: 0, page: 1},
      })
    },
    forceRerender: function() {
      this.componentKey += 1
    },
    chgPage: function(value) {
      this.$router.push({
        path: '/cat/video',
        name: 'Video',
        query: { tab: this.tab, tag: this.stdTag, page: value }
      })
    },
  },

  data: () => ({
    appTitle : 'Products',
    stdTag: 'all',
    tab: 0,
    vList: null,
    tagList: [],
    schTagList: null,
    err: null,
    rangeList: 15,
    page: null,
    pageCount: 1,
    componentKey: 0,
  }),

};
</script>

<style>
  .text{ height:80px; text-overflow:ellipsis; word-wrap:break-word;
          display: -webkit-box;-webkit-line-clamp:2; -webkit-box-orient:vertical;
          overflow:hidden }
</style>
