<template>
  <div>

  <v-card
    :loading="loading"
    class="mx-auto my-6 pt-4 rounded-xl"
    width="400">

    <v-card-title
      class="justify-center mb-4 primaryText--text">
        Sign In
    </v-card-title>

    <v-card-text class="mt-6 px-6 pb-0 justify-center">
      <v-text-field
        class="mb-2"
        v-model="email"
        dense outlined
        @update:error="chkSum(0)"
        :rules="[rules.required, rules.email]"
        label="Email">
      </v-text-field>
      <v-text-field
        v-model="password"
        @update:error="chkSum(1)"
        @keyup.enter="getSalt"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show ? 'text' : 'password'"
        label="Password"
        dense outlined
        @click:append="show = !show">
      </v-text-field>
      <v-switch
        v-model="swt"
        inset
        color="primary"
        label="Remember me">
      </v-switch>
      <v-btn
        class="mt-4"
        :disabled="chk[0] || chk[1]"
        @click="getSalt"
        color="primary" width="100%">
        Sign&nbsp;In
      </v-btn>
    </v-card-text>

    <div
      class="text-right text-subtitle-2 primaryText--text mr-3">
      <v-btn
        class="text-caption"
        color="secondaryText lighten-2"
        @click="notReady"
        plain>
          Forgot&nbsp;your&nbsp;password?
      </v-btn>
    </div>

    <v-divider class="menuDiv mx-6 mt-4"></v-divider>

    <v-card-title
      class="justify-center text-subtitle-2 primaryText--text pt-4">
      <span class="mr-1 mb-3">Don't have an account?</span>
      <v-btn
        class="text-subtitle-2 mb-3"
        color="fourthText lighten-2"
        @click="goRoute('/acc/join', 'Join')"
        plain>
          Sign&nbsp;Up
      </v-btn>
    </v-card-title>

  </v-card>

  </div>
</template>

<script>
import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

Vue.use(VueCryptojs)

export default {
  name: 'Login',

  components: {

  },

  props: {

  },

  created: function () {
    this.$emit('setAppTitle', this.appTitle)
    this.setTitle()
  },

  mounted: function () {

  },

  methods: {
    getSalt: function() {
      instance
        .post('getsalt', {email: this.email})
        .then(response => ( this.accessAccount(response.data) ))
        .catch(error => (this.err = error))
    },
    accessAccount: function(res) {
      console.log(res)
      if(res != 'failed'){
        let decryptPass = this.$CryptoJS.AES.decrypt(res[0].password, res[0].salt).toString(this.CryptoJS.enc.Utf8)

        if(this.password == decryptPass){
          //Login Success
          instance
            .post('createSession', {email: this.email})
            .then(response => ( this.loginSuccess(response.data) ))
            .catch(error => (this.err = error))
        }
        else{
          //password not matching message printing
          this.$emit('setSnak', 'Please check your password again.')
        }
      }
      else{
        //login failed message printing
        this.$emit('setSnak', 'No matching accounts found.')
      }

    },
    loginSuccess: function(res) {
      this.list = res
      sessionStorage.setItem('token', res)
      this.$router.go()
    },
    setTitle: function() {
      this.$emit('setMainTitle', this.mainTitle)
      this.$emit('setSubTitle', this.subTitle)
    },
    chkSum: function(value) {
      this.chk[value] = false
    },
    goRoute: function(route, value) {
      this.$router.push({
        path: route,
        name: value,
      })
    },
    notReady: function() {
      this.$emit('setSnak', 'The password search is still under development.')
    },
  },

  data: () => ({
    appTitle: 'LoginAccount',
    mainTitle: 'Welcome back',
    subTitle: 'Enter your email and password to sign in',
    chk: [true, true],
    email: '',
    password: '',
    list: null,
    show: false,
    loading: false,
    swt: false,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
  }),
};
</script>
