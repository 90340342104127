<template>
  <v-app-bar height="42"
    class="secondaryText rounded-lg thirdText--text">
    <v-app-bar-nav-icon @click = "chgDrawer()">
      <v-icon class="thirdText--text">mdi-menu-open</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="text-body-1 font-weight-bold">{{appBarTitle}}</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-tooltip bottom v-if="!chkTex">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
          color="thirdText"
          @click="chkTex = !chkTex"
          v-bind="attrs" v-on="on">
          <v-icon color="thirdText">mdi-magnify</v-icon>
        </v-btn>
      </template>
      <span>검색</span>
    </v-tooltip>

    <div class="textFieldSize font-weight-bold" v-if="chkTex">
      <v-text-field
        class="custom-label-color"
        v-model="searchField"
        label="Search"
        @click:append-outer="actSearch()"
        @keyup.enter="actSearch()"
        @click:prepend="clearSearch()"
        append-outer-icon="mdi-magnify"
        prepend-icon="mdi-close"
        hide-details
        dense single-line dark>
      </v-text-field>
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
          color="thirdText"
          v-bind="attrs" v-on="on">
          <v-icon color="thirdText">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <span>세부메뉴</span>
    </v-tooltip>

  </v-app-bar>
</template>

<script>
export default {
  name: 'ctBar',

  props: {
    appBarTitle: String,
  },

  components: {

  },

  methods: {
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
    actSearch: function () {
      this.$emit('setSearchField', this.searchField)
    },
    clearSearch: function () {
      this.chkTex = !this.chkTex
      this.searchField = ''
      this.actSearch()
    },
  },

  data: () => ({
    miniVariant: false,
    searchField: '',
    chkTex: false,
  }),
};
</script>

<style>
  .textFieldSize{max-width: 180px;}
  .v-input__icon .mdi-close::before { color: #ffffff !important; }
  .v-input__icon .mdi-magnify::before { color: #ffffff !important; }
  .custom-label-color .v-label { color: #ffffff !important; }
</style>
