<template>
  <div>
    <link href="https://unpkg.com/@videojs/themes@1/dist/forest/index.css" rel="stylesheet">
    <v-card
      class="mx-auto pb-4 grey darken-4 primaryText--text rounded-lg">

      <video id="my-video" class="video-js vjs-theme-forest"></video>

      <v-divider class="mt-10 mb-4 mx-6 primaryText"></v-divider>

      <v-card-title class="mx-4 text-h5 font-weight-black">
        {{ productList != null ? productList.avTitle : ''}}
      </v-card-title>

      <v-card-subtitle class="mx-4 text-Subtitle-2 primaryText--text">
        {{productList != null ? productList.productName : ''}}
      </v-card-subtitle>

      <div class="mx-8">
        <span class="text-button font-weight-bold mr-4">Duration&nbsp;:</span>
        <span class="text-button font-weight-bold mr-4">{{productList != null ? productList.runTime : ''}}</span>
      </div>

      <div class="mt-2 mx-8">
        <span class="text-button font-weight-bold mr-4">Resolution&nbsp;:</span>
        <span class="text-button font-weight-bold mr-4">{{productList != null ? productList.avResolution : ''}}</span>
      </div>

      <div class="mt-2 mx-8">
        <span class="text-button font-weight-bold mr-4">Studio&nbsp;:</span>
        <span class="text-button font-weight-bold mr-4">{{productList != null ? productList.productionCom : ''}}</span>
      </div>

      <div class="mt-2 mx-8">
        <span class="text-button font-weight-bold mr-4">Starring&nbsp;:</span>
      </div>

      <div class="mx-8">
        <v-chip color="secondaryText"
          class="pb-1 pl-2 mr-1 mt-1 white--text font-weight-black"
          v-for="(lists, i) in actorList" :key="i"
          :to="{name: 'StarDetail', query: {pk: lists.avActor_Pk}}"
          link>
          <v-avatar>
            <v-icon small>mdi-face-woman-outline</v-icon>
          </v-avatar>
          <span v-if="lists.jpName != ''">{{lists.jpName}}</span>
          <span v-else-if="lists.enName != ''">{{lists.enName}}</span>
          <span v-else>{{lists.koName}}</span>
        </v-chip>
      </div>

      <div class="mt-2 mx-8">
        <span class="text-button font-weight-bold mr-4">Tags&nbsp;:</span>
        <v-chip class="pt-1 pl-2 white--text font-weight-black"
          v-if="productList != null && productList.avTag != ''"
          color="#0D47A1">
          <v-avatar>
            <v-icon small>mdi-tag-outline</v-icon>
          </v-avatar>
          <span>{{productList.avTag}}</span>
        </v-chip>
      </div>

      <v-divider class="mt-6 mb-4 mx-6 primaryText"></v-divider>

      <v-card-title class="mx-4 text-h6 font-weight-bold">
        Scenes From This Movie
      </v-card-title>

      <v-card-subtitle class="mx-4 secondaryText--text">
        Service to be released&#46;
      </v-card-subtitle>

      <v-divider class="mt-6 mb-4 mx-6 primaryText"></v-divider>

    </v-card>

  </div>
</template>

<script>
import axios from 'axios';
import 'video.js/dist/video-js.css'
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css'
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'Play',

  components: {

  },

  props: {

  },

  created: function () {

    this.avPd_Pk = this.$route.query.avPd_Pk
    this.avActor_Pk = this.$route.query.avActor_Pk

    this.getPlayProduct()
    this.getPlayVideo()
    this.getPlayActor()

    this.$emit('setAppTitle', this.appTitle)

  },

  mounted: function () {
    this.videojs = require('video.js')
    require('videojs-seek-buttons')
    require('@silvermine/videojs-quality-selector')(this.videojs)

    this.player = this.videojs('my-video', this.playerOptions)
    this.player.seekButtons({ forward: 10, back: 10 })
  },

  beforeDestroy: function () {
    if (this.player) {
      this.player.dispose()
    }
  },

  methods: {
    getPlayActor: function (){
      instance
        .post('getPlayActor', {pk: this.avPd_Pk})
        .then(response => (this.actorList = response.data))
        .catch(error => (this.err = error))
    },
    getPlayVideo: function (){
      instance
        .post('getPlayVideo', {pk: this.avPd_Pk})
        .then(response => ( this.setVideo(response) ))
        .catch(error => (this.err = error))
    },
    getPlayProduct: function (){
      instance
        .post('getPlayProduct', {pk: this.avPd_Pk})
        .then(response => ( this.setProduct(response) ))
        .catch(error => (this.err = error))
    },
    setVideo: function (res){
      this.videoList.splice(0)

      res.data.forEach(function(items) {
          items.videoPath = process.env.VUE_APP_API_URL + items.videoPath
      });

      this.videoList = res.data

      if(this.player != null && this.videoList.length > 0){
        let res = []

        this.videoList.forEach((list, i) => {
          var std = false
          if(i==0) std = true

          let sources = {
            src: list.videoPath,
            type: 'video/mp4',
            label: 'ep.' + (i+1),
            selected: std
          }

          res.push(sources)
        })  //for

        this.player.src(res)

      } //if

    },
    setProduct: function (res){
      this.productList = res.data
      this.playerOptions.poster = this.productList.avThumbnail
      this.player.poster(this.productList.avThumbnail)
    },
    returnSearch: function() {

    },
  },

  data: () => ({
    appTitle: 'Play',
    avPd_Pk: '',
    avActor_Pk: '',
    actorList: null,
    videoList: [],
    productList: null,
    err: null,
    videojs: null,
    player: null,
    blobItc: null,
    btnLoading: true,
    btnDisabled: [],

    playerOptions: {
      playbackRates: [.5, .75, 1, 1.25, 1.5, 2],
      durationDisplay: true,
      autoplay: false,
      muted: false,
      loop: false,
      preload: 'auto',
      language: 'en',
      aspectRatio: '16:9',
      fluid: true,
      poster: '',
      controls: true,
      notSupportedMessage: '지원되지 않은 비디오 유형 또는 잘못된 파일 경로입니다',
      controlBar: {
        durationDisplay: true,
        playToggle: true,
        remainingTimeDisplay: true,
        progressControl: true,
        qualitySelector: true,
        fullscreenToggle: true,
      },
    },
  }),
};
</script>
