<template>
  <v-container>
    <v-row
      v-for="(lists, i) in actorDetail" :key="i"
      class=""
      no-gutters>
      <v-col cols="4"
        class="grey darken-4 rounded-lg pt-4 mb-2">
        <v-card
          class="mx-auto text-center grey darken-4 elevation-0"
          width="120" height="180">
          <v-img
            class="align-end"
            :src="lists.actorImg" height="120"
            lazy-src="../assets/images/unKnownStar.png"
            contain position="center">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="primaryText">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-subtitle class="text-Subtitle-2 font-weight-black primaryText--text">
            {{lists.jpName}}
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="7"
        class="grey darken-4 rounded-lg ml-2 mb-2 pa-3">
        <div class="mb-1 text-h6 font-weight-black primaryText--text">
          Profile
        </div>
        <div class="text-Subtitle-2 primaryText--text">
          <span>Foreign name&nbsp;&#58;&nbsp;&nbsp;</span>
          <span>{{lists.enName}}&nbsp;&#47;&nbsp;{{lists.koName}}</span>
        </div>
        <div class="text-Subtitle-2 primaryText--text">
          <span>Date of Birth&nbsp;&#58;&nbsp;&nbsp;</span>
          <span>{{lists.birthDay != null ? lists.birthDay : 'none'}}</span>
        </div>
        <div class="text-Subtitle-2 primaryText--text">
          <span>Height&nbsp;&#58;&nbsp;&nbsp;</span>
          <span>{{lists.height != null ? lists.height+'cm' : 'none'}}</span>
        </div>
        <div class="text-Subtitle-2 primaryText--text">
          <span>Bust-Waist-Hip&nbsp;Ratio&nbsp;&#58;&nbsp;&nbsp;</span>
          <span>{{lists.bwhRatio != null ? lists.bwhRatio : 'none'}}</span>
        </div>
        <div class="text-Subtitle-2 primaryText--text">
          <span>Casting Film&nbsp;&#58;&nbsp;&nbsp;</span>
          <span>{{videoList != null ? videoList.length : 0}}&nbsp;cases&#46;</span>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="mt-3" cols="12">
        <span class="text-h6 font-weight-black primaryText--text">
          Products
        </span>
      </v-col>
    </v-row>

    <videoView :videoComp="videoList" :mode="'small'" :page="1"/>

  </v-container>
</template>

<script>
import axios from 'axios';
import videoView from '../components/VideoListComp.vue';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'StarDetail',

  components: {
    videoView,
  },

  props: {

  },

  created: function () {
    this.pk = this.$route.query.pk

    this.$emit('setAppTitle', this.appTitle)

    this.getActorDetail()
    this.getActorToVideo()
  },

  methods: {
    getActorDetail: function (){
      instance
        .post('getActorDetail', {pk: this.pk})
        .then(response => ( this.actorDetail = response.data ))
        .catch(error => (this.err = error))
    },
    getActorToVideo: function (){
      instance
        .post('getActorToVideo', {pk: this.pk})
        .then(response => ( this.videoList = response.data ))
        .catch(error => (this.err = error))
    },
    chgDrawer: function() {
      this.$emit('chgDrawer')
    }
  },

  data: () => ({
    appTitle : 'StarDetail',
    pk: '',
    actorDetail: null,
    videoList: null,
  }),
};
</script>
