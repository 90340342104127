<template>
  <v-card
    class="pt-10 rounded-lg">
    <v-divider class="primaryText mx-16"></v-divider>
    <div class="text-center d-flex flex-column align-center justify-center">
      <v-icon
        class="mt-12 mb-6"
        color="red" x-large>
          mdi-alert-rhombus-outline
        </v-icon>
        <v-card-title class="primaryText--text">
          This&nbsp;page&nbsp;does&nbsp;not&nbsp;have&nbsp;access&#46;
        </v-card-title>
        <v-card-subtitle class="pt-2 mb-4 primaryText--text">
          If you have any questions, please contact your administrator
        </v-card-subtitle>
      </div>
    <v-divider class="primaryText mx-16"></v-divider>
    <v-card-actions class="text-center d-flex flex-column align-center justify-center">
      <v-btn
         class="mt-4 mb-4"
         @click="goBack"
         color="secondaryText" text>
         Return to
       </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AccessDenied',

  components: {

  },

  props: {

  },

  created: function () {
    this.$emit('setAppTitle', this.appTitle)
  },

  mounted: function () {

  },

  methods: {
    goBack: function () {
      this.$router.go(-1)
    },
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
  },

  data: () => ({
    appTitle: 'Access Denied',
  }),
};
</script>
