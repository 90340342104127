<template>

    <v-card
      class="mx-auto grey darken-4 rounded-lg">
      <v-card-title class="ml-3 pt-7 primaryText--text text-h4 font-weight-black mt-4">
        Insert&nbsp;Product
      </v-card-title>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        ProductName
      </v-card-title>

      <v-text-field
        class="mx-6"
        dense
        v-model="productName"
        prepend-inner-icon="mdi-tag-text-outline"
        background-color="grey darken-3"
        counter="20"
        @change="chkForm"
        :rules="[rules.required, rules.counter20]"
        hint="010112-001, 010112_001, MKBD-001"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Title
      </v-card-title>

      <v-text-field
        class="mx-6"
        dense
        v-model="title"
        prepend-inner-icon="mdi-format-title"
        background-color="grey darken-3"
        counter="120"
        @change="chkForm"
        :rules="[rules.required]"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Resolution
      </v-card-title>

      <v-select
        class="fourthText--text mx-6"
        dense
        v-model="resolution"
        prepend-inner-icon="mdi-quality-high"
        color="fourthText"
        background-color="grey darken-3"
        :items="items"
        outlined>
      </v-select>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Tag
      </v-card-title>

      <v-text-field
        class="mx-6"
        dense
        v-model="tag"
        prepend-inner-icon="mdi-tag-outline"
        background-color="grey darken-3"
        :rules="[rules.counter20]"
        counter="20"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        RunTime
      </v-card-title>

      <v-text-field
        class="mx-6"
        dense
        v-model="runtime"
        prepend-inner-icon="mdi-timer-outline"
        background-color="grey darken-3"
        counter="8"
        hint="01:10:59"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Thumbnail
      </v-card-title>

      <v-text-field
        class="mx-6"
        dense
        v-model="thumbnail"
        prepend-inner-icon="mdi-image-outline"
        background-color="grey darken-3"
        counter="100"
        hint="https://neistar.synology.me/video/~"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Production
      </v-card-title>

      <v-text-field
        class="mx-6"
        dense
        v-model="production"
        color="red"
        prepend-inner-icon="mdi-office-building-outline"
        background-color="grey darken-3"
        :rules="[rules.counter20]"
        counter="20"
        hint="heyzo, tokyohot"
        outlined dark>
      </v-text-field>

      <v-card-actions class="pt-6">
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-lg mr-4 mb-4"
          color="secondaryText"
          :disabled="btnDisabled"
          @click="addProductItem"
          dark>
          <v-icon>mdi-pencil-plus-outline</v-icon>
          <span class="mx-1">Submit</span>
        </v-btn>
      </v-card-actions>
      {{err}}
    </v-card>

</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'VideoConn',

  components: {

  },

  props: {
    comTitle: String,
  },

  created: function () {

  },

  methods: {
    addProductItem: function () {
      let throwData = [this.productName,
        this.title, this.resolution, this.tag, this.runtime,
        this.thumbnail, this.production]

      instance
        .post('addProductItem', {product: throwData})
        .then(response => (this.axiosRes = response.data))
        .catch(error => (this.err = error))
    },
    chkForm: function () {
      if(this.productName.length > 3) this.btnDisabled = false
      else if(this.productName.length <= 3) this.btnDisabled = true

      if(this.title != '') this.btnDisabled = false
      else if(this.title == '') this.btnDisabled = true
    },
  },

  data: () => ({
    err: null,
    axiosRes: null,
    productName: '',
    title: '',
    resolution: 0,
    tag: '',
    runtime: '',
    thumbnail: '',
    production: '',
    btnLoading: false,
    btnDisabled: true,
    loading: false,
    items: ['UHD', 'FHD', 'HD', 'SD'],
    rules: {
        required: value => !!value || '필수 입력 항목입니다.',
        counter20: value => value.length <= 20 || 'Max 20 characters',
    },
  }),
};
</script>

<style>
.theme--light.v-list{ background: #424242; }
.v-list-item__content{ color: #fff; }
.select { max-width: 220px; }
.v-input__icon .theme--dark.v-icon { color: #9e9e9e !important; }

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input { color: #9e9e9e !important; }
</style>
