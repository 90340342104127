<template>

  <v-row align-content="center">
    <v-col cols="2"
      v-if="bookList == null || err != null">
      <v-skeleton-loader
       class="mx-auto"
       width="100" height="136"
       type="image">
      </v-skeleton-loader>
      <v-skeleton-loader
       class="mx-auto mt-2"
       width="100"
       type="text">
      </v-skeleton-loader>
    </v-col>
    <v-col
      class=""
        v-for="(lists, i) in bookList" :key="i"
        @click="goRoute(lists.comicFk, lists.thumbnail)"
        cols="2" align="center">
        <v-img
          class="white--text align-end"
          :src="lists.thumbnail"
          lazy-src="../assets/images/Webtoon.png"
          height="140" contain position="center">

          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center" justify="center">

              <v-progress-circular
                indeterminate
                color="primaryText">
              </v-progress-circular>
            </v-row>
          </template>

        </v-img>
        <span class="primaryText--text">{{lists.title}}</span>
    </v-col>
  </v-row>

</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'Webtoon',

  components: {

  },

  props: {

  },

  created: function () {
    this.$emit('setAppTitle', this.appTitle)
    this.getWebToonList()
  },

  mounted: function () {

  },

  methods: {
    getWebToonList: function (){
      instance
        .post('comicList', {category: 'webtoon'})
        .then(response => ( this.bookList = response.data ))
        .catch(error => (this.err = error))
    },
    goRoute: function (value, img) {
      this.$router.push({
        path: '/cat/webtoondetail',
        name: 'WebtoonDetail',
        params: {comic: value, comicThumb: img},
      })
    },
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
  },

  data: () => ({
    appTitle: 'Webtoon',
    bookList: null,
    err: null,
  }),
};
</script>
