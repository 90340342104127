import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {

      },
      dark: {
        primaryText: '#9e9e9e',
        secondaryText: '#e75546',
        thirdText: '#fff',
        fourthText: '#4b82f2',
        subText: '#606e79',
        mainText: '#9c9996',
        menuDiv: '#4f4f4f',
        backgroundColor: '#121212',
      },
    },
  },
});
