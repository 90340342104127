<template>
  <v-app id="inspire">

    <v-navigation-drawer app
      v-model="drawer" temporary
      color="grey darken-4"
      stateless width="80%"
      dark>

      <v-system-bar color="grey darken-4 mb-2" height="45">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="chgDrawer"
              color="grey darken-4"
              elevation="0" x-small
              v-bind="attrs" v-on="on">
              <v-icon size="26"
                color="primaryText font-weight-bold">mdi-chevron-double-left</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-system-bar>

      <logo @chgDrawer = "chgDrawer()"/>

      <v-divider class="menuDiv"></v-divider>

      <v-list>
        <v-subheader
          class="mx-1 subText--text font-weight-bold">
          Account
        </v-subheader>
        <v-row no-gutters v-if="token == ''"
          class="rounded-lg px-2">
          <v-col
            v-for="(item, i) in accountMenu" :key="i">
            <v-card link
              class="grey darken-4 elevation-0 text-center d-flex flex-column align-center justify-center"
              @click="jumpNav(item.path, item.text)">
              <v-card-actions>
                <v-icon size="25" color="mainText">{{item.icon}}</v-icon>
                <span class="ml-3 mainText--text text-Subtitle-2">{{item.text}}</span>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else
          class="rounded-lg px-2"
          align="center" align-content="center" justify="center">

          <v-col cols="10">
            <v-card link
              class="grey darken-4 elevation-0 text-center d-flex flex-column align-center justify-center">
              <v-card-actions>
                <v-avatar color="indigo">
                  <v-icon color="white">mdi-face-woman-profile</v-icon>
                </v-avatar>
                <span class="ml-3 mainText--text text-Subtitle-2">{{this.decodeJwt[0].email}}</span>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card link
              @click="jumpNav('/cat/profile', 'Profile')"
              class="grey darken-4 elevation-0 text-center d-flex flex-column align-center justify-center">
              <v-card-actions>
                <v-icon size="25" color="mainText">mdi-account-edit-outline</v-icon>
                <span class="ml-3 mainText--text text-Subtitle-2">Profile</span>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card link
              @click="sessionClear"
              class="grey darken-4 elevation-0 text-center d-flex flex-column align-center justify-center">
              <v-card-actions>
                <v-icon size="25" color="mainText">mdi-power</v-icon>
                <span class="ml-3 mainText--text text-Subtitle-2">Logout</span>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-list>

      <v-list class="mt-2">
        <v-subheader
          class="mx-1 subText--text font-weight-bold">
          Category
        </v-subheader>
        <v-row no-gutters
          class="rounded-lg px-2">
          <v-col cols="3"
            v-for="(item, i) in categoryMenu" :key="i">
            <v-card link
              class="grey darken-4 elevation-0 mb-1 text-center d-flex flex-column align-center justify-center"
              @click="jumpNav(item.path, item.text)">
              <v-card-actions>
                <v-icon size="20" color="mainText">{{item.icon}}</v-icon>
              </v-card-actions>
              <div class="mainText--text text-body-2">{{item.text}}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-list>

      <v-list class="mt-2">
        <v-subheader
          class="mx-1 subText--text font-weight-bold">
          Manage
        </v-subheader>
        <v-row no-gutters
          class="rounded-lg px-2">
          <v-col cols="3"
            v-for="(item, i) in manageMenu" :key="i">
            <v-card link
              class="grey darken-4 elevation-0 mb-3 text-center d-flex flex-column align-center justify-center"
              @click="jumpNav(item.path, item.text)">
              <v-card-actions>
                <v-icon size="20" color="mainText">{{item.icon}}</v-icon>
              </v-card-actions>
              <div class="mainText--text text-body-2">{{item.text}}</div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters
          class="rounded-lg px-2 mt-2">
          <v-col cols="3"
            v-for="(item, i) in maintainMenu" :key="i">
            <v-card link
              class="grey darken-4 elevation-0 mb-3 text-center d-flex flex-column align-center justify-center"
              @click="jumpNav(item.path, item.text)">
              <v-card-actions>
                <v-icon size="20" color="mainText">{{item.icon}}</v-icon>
              </v-card-actions>
              <div class="mainText--text text-body-2">{{item.text}}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-list>

      <v-divider class="menuDiv"></v-divider>

    </v-navigation-drawer>

    <v-main>
       <v-container>
         <router-view @chgDrawer = "chgDrawer()"></router-view>
       </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>

  </v-app>
</template>

<script>
import logo from './components/LogoComp.vue';
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'App',

  components: {
    logo,
  },

  created: function () {
    if(sessionStorage.getItem('token') == null)
      this.token = ''
    else{
      this.token = sessionStorage.getItem('token')
      this.decodeJwt = VueJwtDecode.decode(this.token)
    }
  },

  methods: {
    chgDrawer: function() {
      this.drawer = !this.drawer
    },
    jumpNav: function(route, txt) {
      this.$router.push({
        path: route,
        name: txt,
      })
      this.drawer = !this.drawer
    },
    sessionClear: function () {
      sessionStorage.clear()
      this.$router.go()
    },
  },

  data: () => ({
    drawer: false,
    token: null,
    decodeJwt: null,
    categoryMenu: [
      { text: 'Video', icon: 'mdi-movie-open', path:'/cat/video' },
      { text: 'Star', icon: 'mdi-star-outline', path:'/cat/star'},
      { text: 'Cartoon', icon: 'mdi-book-open-page-variant-outline', path:'/cat/cartoon' },
      { text: 'Webtoon', icon: 'mdi-notebook-outline', path:'/cat/webtoon' },
    ],
    accountMenu: [
      { text: 'Login', icon: 'mdi-account-circle', path:'/acc/login'},
      { text: 'Join', icon: 'mdi-account-circle-outline', path:'/acc/join' },
    ],
    manageMenu: [
      { text: 'Product', icon: 'mdi-movie-open-cog', path:'/manage/product' },
      { text: 'Actor', icon: 'mdi-star-cog-outline', path:'/manage/actor' },
      { text: 'VideoPath', icon: 'mdi-movie-open-edit', path:'/manage/videopath' },
      { text: 'Account', icon: 'mdi-account-cog-outline', path:'/manage/account' },
    ],
    maintainMenu: [
      { text: 'ProductAdd', icon: 'mdi-movie-open-plus-outline', path:'/cat/productAdd' },
      { text: 'ActorAdd', icon: 'mdi-star-plus-outline', path:'/' },
      { text: 'ComicAdd', icon: 'mdi-book-plus-outline', path:'/' },
    ],

  }),


};
</script>

<style>

</style>
