<template>
  <v-row class="mt-2">
    <v-col
      class=""
      v-for="(lists, i) in videoComp" :key="i">

      <v-card
        :to="{path: '/cat/play', name: 'Play',
          query: {avPd_Pk: lists.avPd_Pk, page: page, tag: stdTag, tabNum: tab} }"
        class="mx-auto grey darken-4 primaryText--text"
        :min-width="mode == 'small' ? 190 : 360"
        :max-width="mode == 'small' ? 190 : 360" link>

        <v-img
          class="white--text align-end"
          :src="lists.avThumbnail"
          lazy-src="../assets/images/lazyImgSrc.png"
          :height="mode == 'small' ? 105 : 205" contain position="center">

          <v-card-subtitle
            class="white--text text-right text-Subtitle-2 font-weight-black">
            <span>{{lists.runTime}}</span>
          </v-card-subtitle>

          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center" justify="center">

              <v-progress-circular
                indeterminate
                color="primaryText">
              </v-progress-circular>
            </v-row>
          </template>

        </v-img>

        <div v-if="mode=='small'">
          <v-card-title class="pb-0 text-subtitle-1 font-weight-bold">
            <v-icon v-if="lists.avResolution == ''"
              class="mr-1 pb-1 primaryText--text" size="16">
              mdi-movie-open-off-outline
            </v-icon>
            <div>{{lists.productName}}</div>
          </v-card-title>
          <v-card-text class="primaryText--text text-right mt-1">
            <span>{{lists.productionCom}}</span>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-title class="pb-0 text-h6 font-weight-bold text">
            <span>{{lists.avTitle}}</span>
          </v-card-title>
          <v-card-text class="primaryText--text text-right mt-1">
            <v-icon v-if="lists.avResolution == ''"
            class="mr-3 mb-1 primaryText--text" size="16">
              mdi-movie-open-off-outline
            </v-icon>
            {{lists.productionCom}}
          </v-card-text>
        </div>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'VideoListView',

  props: {
    videoComp: Array,
    page: Number,
    stdTag: String,
    tab: Number,
    mode: String,
  },

  components: {

  },

  mounted: async function () {
    await this.$nextTick()
  },

  beforeUpdate: function () {

  },

  methods: {

  },

  data: () => ({

  }),
};
</script>
