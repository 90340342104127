<template>
  <div class="mt-3">

    <actorView :starComp="actorList"
      :key="componentKey" :page="page"/>

    <v-row>
      <v-col>
        <v-pagination
          v-model="page"
          color="green"
          :length="pageCount"
          :total-visible="9"
          @input="chgPage" dark>
        </v-pagination>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import actorView from '../components/ActorListComp.vue';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'Star',

  components: {
    actorView,
  },

  props: {
    comTitle: String,
  },

  created: function () {
    if(this.$route.query.page == null) this.page = 1
    else this.page = Number(this.$route.query.page)

    this.getActorList()
    this.getActorCnt()

    this.$emit('setAppTitle', this.appTitle)
  },

  computed: {

  },

  methods: {
    getActorList: function (){
      instance
        .post('getActorList', {txt: this.sField, range: this.rangeList, page: this.page})
        .then(response => ( this.actorList = response.data ))
        .catch(error => (this.err = error))
    },
    getActorCnt: function (){
      instance
        .post('getActorCnt', {txt: this.sField, range: this.rangeList})
        .then(response => ( this.pageCount = response.data ))
        .catch(error => (this.err = error))
    },
    forceRerender: function() {
      this.componentKey += 1
    },
    chgPage: function(value) {
      this.$router.push({
        path: '/cat/star',
        name: 'Star',
        query: { page: value }
      })
    },
    goSearch: function() {
      this.$router.push({
        path: '/cat/lookresult',
        name: 'LookResultComp',
        query: { tabnum : 1, },
      })
    },
    returnSearch: function() {

    },
  },

  data: () => ({
    appTitle: 'Starring',
    actorList: null,
    sField: '',
    page: 1,
    pageCount: 1,
    rangeList: 16,
    componentKey: 0,
  }),
};
</script>
<style>

</style>
