<template>
  <div>
    <vAppBar
      class="mt-2"
      @chgDrawer = chgDrawer()
      :appBarTitle = "appTitle" />
    <HelloWorld msg="welcome"/>
  </div>
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue';
import vAppBar from '../components/CustomBar.vue';

export default {
  name: 'Home',

  components: {
    HelloWorld,
    vAppBar,
  },

  props: {
    comTitle: String,
  },

  created: function () {

  },

  methods: {
    chgDrawer: function() {
      this.$emit('chgDrawer')
    }
  },

  data: () => ({
    appTitle : 'Home',
  }),
};
</script>
