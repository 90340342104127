<template>
  <v-card
    class="py-6 text-center primaryText--text">
    <v-avatar
      color="deep-purple darken-4" size="170">
      <v-img
        class=""
        :src="require('../assets/images/' + this.decodeJwt[0].member_rating.toLowerCase() + '.png')"
        height="80" contain>
      </v-img>
    </v-avatar>
    <v-card-title class="justify-center text-h5 font-weight-bold">
      {{this.decodeJwt[0].name}}
    </v-card-title>
    <v-card-subtitle class="text-caption font-weight-light font-italic">
      {{this.decodeJwt[0].member_rating}}
    </v-card-subtitle>
    <v-card-title class="teal--text mx-4 text-body-2 font-weight-bold">
      Email&nbsp;Address
    </v-card-title>
    <v-text-field dense disabled
      append-outer-icon="mdi-check-circle-outline"
      prepend-icon="mdi-email"
      class="mx-8" color="teal"
      :label="this.decodeJwt[0].email">
    </v-text-field>
    <v-card-title class="teal--text mx-4 text-body-2 font-weight-bold">
      Gender
    </v-card-title>
    <v-btn-toggle
      v-model="genderChk" mandatory>
        <div class="mr-3">
          <v-btn class="px-8 py-6 lineBox">
            <v-icon class="d-block" color="primaryText" large>mdi-gender-male</v-icon>
          </v-btn>
          <span class="d-block mt-1 primaryText--text text-body-2">Male</span>
        </div>
        <div class="mr-3">
          <v-btn class="px-8 py-6 lineBox">
            <v-icon class="d-block" color="primaryText" large>mdi-gender-female</v-icon>
          </v-btn>
          <span class="d-block mt-1 primaryText--text text-body-2">Female</span>
        </div>
        <div>
          <v-btn class="px-8 py-6 lineBox">
            <v-icon class="d-block" color="primaryText" large>mdi-gender-male-female</v-icon>
          </v-btn>
          <span class="d-block mt-1 primaryText--text text-body-2">Other</span>
        </div>
    </v-btn-toggle>
    <v-card-title class="teal--text mx-4 text-body-2 font-weight-bold">
      BirthDay
    </v-card-title>
    <v-dialog
      v-model="modal" ref="dialog"
      :return-value.sync="date"
      persistent width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="mx-8"
          solo hide-details dense
          v-model="date"
          prepend-icon="mdi-cake-variant-outline"
          v-bind="attrs" v-on="on" readonly>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn
          text color="primary"
          @click="modal = false">
           Cancel
        </v-btn>
        <v-btn
          text color="primary"
          @click="$refs.dialog.save(date)">
           OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-card-title class="teal--text mx-4 text-body-2 font-weight-bold">
      Phone&nbsp;number
    </v-card-title>
    <v-text-field dense
      v-model="phone"
      prepend-icon="mdi-phone"
      :label="this.decodeJwt[0].phone"
      :rules="[rules.acceptNumber, rules.counter]"
      hint="000-0000-0000"
      counter maxlength="15"
      class="mx-8" color="teal">
    </v-text-field>
    <v-card-title class="teal--text mx-4 text-body-2 font-weight-bold">
      Address
    </v-card-title>
    <v-text-field dense
      v-model="address"
      prepend-icon="mdi-map-marker-outline"
      :label="this.decodeJwt[0].address"
      :rules="[rules.counter]"
      hint="312 Cherry Lane New Castle Delaware"
      counter maxlength="100"
      class="mx-8" color="teal">
    </v-text-field>
    <div class="mx-8 mt-6 mb-2">
      <v-btn
        @click="setAccountDetail"
        color="primary" width="100%">
          modify
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode'
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'Profile',

  components: {

  },

  props: {

  },

  created: function () {
    if(sessionStorage.getItem('token') == null)
      this.token = ''
    else{
      this.token = sessionStorage.getItem('token')
      this.decodeJwt = VueJwtDecode.decode(this.token)
      this.date = this.decodeJwt[0].birthday
      this.genderChk = Number(this.decodeJwt[0].gender)
      this.phone = this.decodeJwt[0].phone
      this.address = this.decodeJwt[0].address
    }

    this.$emit('setAppTitle', this.appTitle)
  },

  mounted: function () {

  },

  methods: {
    setAccountDetail: function() {
      instance
        .post('setAccountDetail', {
          email: this.decodeJwt[0].email,
          token: this.token,
          gender: this.genderChk,
          birthday: this.date,
          phone: this.phone,
          address: this.address
        })
        .then(response => ( this.refreshToken(response.data) ))
        .catch(error => (this.err = error))
    },
    refreshToken: function(res) {
      sessionStorage.clear()
      sessionStorage.setItem('token', res)
      this.decodeJwt = VueJwtDecode.decode(res)
      this.date = this.decodeJwt[0].birthday
      this.genderChk = Number(this.decodeJwt[0].gender)
      this.phone = this.decodeJwt[0].phone
      this.address = this.decodeJwt[0].address
    },
  },

  data: () => ({
    appTitle: 'Profile',
    token: null,
    decodeJwt: null,
    modal: 0,
    genderChk: null,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    phone: null,
    address: null,
    componentKey: 0,
    rules: {
      counter: value => value.length <= 100 || 'Max 100 characters',
      min: v => v.length >= 8 || 'Min 8 characters',
      acceptNumber: value => {
        let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        return regPhone.test(value) || 'Please enter it according to the phone number format'
      },
    },
  }),
};
</script>

<style>
  .lineBox { border: 3px solid #9e9e9e; }
  .theme--dark.v-icon { color: #9e9e9e !important; }
  .v-text-field__slot label { color: #9e9e9e !important; }
  .v-text-field__slot input { color: #9e9e9e !important; }
</style>
