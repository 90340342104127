<template>

    <v-card
      class="mx-auto grey darken-4 rounded-lg">
      <v-card-title class="ml-3 pt-7 primaryText--text text-h4 font-weight-black mt-4">
        Video&nbsp;to&nbsp;star&nbsp;connection
      </v-card-title>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Product&nbsp;Primarykey
      </v-card-title>

      <v-select
        class="fourthText--text mx-6"
        dense
        v-model="video"
        @change="chkForm"
        prepend-inner-icon="mdi-filmstrip"
        color="fourthText"
        background-color="grey darken-3"
        item-text="avPd_Pk"
        item-value="pk"
        :items="videoPk"
        outlined>
      </v-select>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Star&nbsp;Primarykey
      </v-card-title>

      <v-select
        class="fourthText--text mx-6"
        dense
        v-model="actor"
        @change="chkForm"
        prepend-inner-icon="mdi-star"
        color="fourthText"
        background-color="grey darken-3"
        item-text="avActor_Pk"
        item-value="pk"
        :items="actorPk"
        outlined>
      </v-select>

      <v-card-actions class="pt-6">
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-lg mr-4 mb-4"
          color="secondaryText"
          :disabled="btnDisabled"
          @click="connVideoToActor"
          dark>
          <v-icon>mdi-pencil-plus-outline</v-icon>
          <span class="mx-1">Submit</span>
        </v-btn>
      </v-card-actions>
      {{err}}{{res}}
    </v-card>

</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'VideoConn',

  components: {

  },

  props: {
    comTitle: String,
  },

  created: function () {
    this.getVideoPk()
    this.getActorPk()
  },

  methods: {
    getVideoPk: function () {

      instance
        .post('getVideoPk', {product: ''})
        .then(response => (this.videoPk = response.data))
        .catch(error => (this.err = error))
    },
    getActorPk: function () {

      instance
        .post('getActorPk', {product: ''})
        .then(response => (this.actorPk = response.data))
        .catch(error => (this.err = error))
    },
    connVideoToActor: function () {
      let pdPk = 'video' + this.video
      let actPk = 'act' + this.actor

      instance
        .post('addVtoAItem', {video: pdPk, star: actPk})
        .then(response => (this.res = response.data))
        .catch(error => (this.err = error))

    },
    chkForm: function () {
      if(this.video != 0 && this.actor != 0){
        this.btnDisabled = false
      }
    },
  },

  data: () => ({
    err: null,
    res: null,
    video: 0,
    actor: 0,
    videoPk: null,
    actorPk: null,
    btnLoading: false,
    btnDisabled: true,
    loading: false,
    rules: {
        required: value => !!value || '필수 입력 항목입니다.',
    },
  }),
};
</script>

<style>
.theme--light.v-list{ background: #424242; }
.v-list-item__content{ color: #fff; }
.select { max-width: 220px; }
.v-input__icon .theme--dark.v-icon { color: #9e9e9e !important; }

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input { color: #9e9e9e !important; }
</style>
