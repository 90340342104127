<template>
  <div class="white--text mt-3">
    <v-row>
      <v-col>
        <v-tabs
          v-model="tab"
          class="rounded-lg"
          background-color="grey darken-4"
          show-arrows dark>
          <v-tab
            class="font-weight-black"
            v-for="(lists, i) in items" :key="i"
            @click="repRouter(i)">
            {{lists.tab}}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col>
        Search&nbsp;results&nbsp;&#58;&nbsp;{{resCnt}}&nbsp;cases&#46;
      </v-col>
    </v-row>

    <videoView v-if="tab==0" :videoComp="resList" :page="page"/>

    <actorView v-else-if="tab==1" :starComp="resList" :page="page"/>

    <v-row>
      <v-col v-if="resCnt!=0">
        <v-pagination
          v-model="page"
          color="green"
          :length="pageCount"
          :total-visible="9" dark
          @input="chgPage"
        ></v-pagination>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import axios from 'axios';
import actorView from './ActorListComp.vue';
import videoView from './VideoListComp.vue';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'Admin',

  components: {
    actorView,
    videoView,
  },

  props: {
    sField: String,
  },

  created: function () {
    if(this.sField == '')
      this.$router.push({ path: '/cat/video', name: 'Video', })

    if(this.$route.query.tabnum == null)
      this.$router.push({ path: '/cat/video', name: 'Video', })

    if(this.$route.query.page != null)
      this.page = Number(this.$route.query.page)

    this.tab = Number(this.$route.query.tabnum)

    if(this.tab == 0){
      this.searchProductVideo()
      this.searchProductCnt()
    }else if(this.tab == 1){
      this.searchStarVideo()
      this.searchStarCnt()
      this.searchStarTotCnt()
    }

    this.$emit('setAppTitle', this.appTitle)
    localStorage.clear();
  },
  beforeUpdate: function () {
    if(this.tab == 0){
      this.searchProductVideo()
      this.searchProductCnt()
    }else if(this.tab == 1){
      this.searchStarVideo()
      this.searchStarCnt()
      this.searchStarTotCnt()
    }
  },

  methods: {
    searchStarVideo: function () {
      instance
        .post('getActorList', {txt: this.sField, range: this.rangeList, page: this.page})
        .then(response => ( this.resList = response.data ))
        .catch(error => (this.err = error))
    },
    searchStarCnt: function () {
      instance
        .post('getActorCnt', {txt: this.sField, range: this.rangeList})
        .then(response => ( this.pageCount = response.data ))
        .catch(error => (this.err = error))
    },
    searchStarTotCnt: function () {
      instance
        .post('getActorTotCnt', {txt: this.sField})
        .then(response => ( this.resCnt = response.data.cnt ))
        .catch(error => (this.err = error))
    },
    searchProductVideo: function () {
      instance
        .post('searchProductTxt', {txt: this.sField, range: this.rangeList, page: this.page})
        .then(response => ( this.resList = response.data ))
        .catch(error => (this.err = error))
    },
    searchProductCnt: function () {
      instance
        .post('searchProductCnt', {txt: this.sField, range: this.rangeList})
        .then(response => ( this.setCnt(response) ))
        .catch(error => (this.err = error))
    },
    setCnt: function (res){
      this.pageCount = res.data.pageCnt
      this.resCnt = res.data.totCnt
    },
    goSearch: function() {

    },
    chgPage: function(value) {
      this.$router.push({
        path: '/cat/lookresult',
        name: 'LookResultComp',
        query: { tabnum: this.tab, page: value }
      })
    },
    returnSearch: function() {
      this.$router.push({
        path: '/cat/video',
        name: 'Video',
      })
    },
    repRouter: function (num) {
      this.$router.replace({
        name: 'LookResultComp',
        query: { tabnum: num},
      })
    },
  },

  data: () => ({
    appTitle : 'Integrated Search',
    resList: null,
    resCnt: 0,
    err: null,
    tab: 0,
    rangeList: 15,
    page: 1,
    pageCount: 1,
    items: [
      { tab: 'Video'},
      { tab: 'Starring' },
    ],
  }),
};
</script>

<style>

</style>
