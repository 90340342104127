<template>

    <v-card
      class="mx-auto grey darken-4 rounded-lg">
      <v-card-title class="ml-3 pt-7 primaryText--text text-h4 font-weight-black mt-4">
        Insert&nbsp;Star
      </v-card-title>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        BirthDay
      </v-card-title>

      <v-dialog
        v-model="modal" ref="dialog"
        :return-value.sync="date"
        persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mx-8 blue--text"
            solo hide-details dense
            v-model="date"
            prepend-icon="mdi-cake-variant-outline"
            v-bind="attrs" v-on="on" readonly>
          </v-text-field>
        </template>
        <v-date-picker
          class="blue--text"
          v-model="date" scrollable>
          <v-spacer></v-spacer>
          <v-btn
            text color="primary"
            @click="modal = false">
             Cancel
          </v-btn>
          <v-btn
            text color="primary"
            @click="$refs.dialog.save(date)">
             OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        Height
      </v-card-title>

      <v-text-field
        class="mx-6"
        v-model="height"
        dense
        prepend-inner-icon="mdi-human-male-height-variant"
        background-color="grey darken-3"
        counter="4"
        hint="160"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        ActorImg
      </v-card-title>

      <v-text-field
        class="mx-6"
        v-model="actorImg"
        dense
        prepend-inner-icon="mdi-file-image-outline"
        background-color="grey darken-3"
        counter="100"
        hint="https://www.naver.com/"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        BWH&nbsp;Ratio
      </v-card-title>

      <v-text-field
        class="mx-6"
        v-model="bwhRatio"
        dense
        prepend-inner-icon="mdi-human-female"
        background-color="grey darken-3"
        hint="B80-W50-H80"
        :rules="[rules.counter]"
        counter="20"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        JapanName
      </v-card-title>

      <v-text-field
        class="mx-6"
        v-model="jpName"
        dense
        prepend-inner-icon="mdi-tag-outline"
        background-color="grey darken-3"
        counter="10"
        @change="chkForm"
        :rules="[rules.required]"
        hint="大場ゆい"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        EnglishName
      </v-card-title>

      <v-text-field
        class="mx-6"
        v-model="enName"
        dense
        prepend-inner-icon="mdi-tag-outline"
        background-color="grey darken-3"
        counter="20"
        @change="chkForm"
        :rules="[rules.required]"
        hint="Yui Oba"
        outlined dark>
      </v-text-field>

      <v-card-title class="ml-3 primaryText--text text-body-1 font-weight-bold">
        KoreaName
      </v-card-title>

      <v-text-field
        class="mx-6"
        v-model="koName"
        dense
        prepend-inner-icon="mdi-tag-outline"
        background-color="grey darken-3"
        :rules="[rules.counter]"
        counter="10"
        hint="오오바 유이"
        outlined dark>
      </v-text-field>

      <v-card-actions class="pt-6">
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-lg mr-4 mb-4"
          color="secondaryText"
          :disabled="btnDisabled"
          @click="addActorItem"
          dark>
          <v-icon>mdi-pencil-plus-outline</v-icon>
          <span class="mx-1">Submit</span>
        </v-btn>
      </v-card-actions>
      {{err}}{{axiosRes}}
    </v-card>

</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'ActorConn',

  components: {

  },

  props: {
    comTitle: String,
  },

  created: function () {

  },

  methods: {
    addActorItem: function () {
      let throwData = [this.date,
        this.height, this.actorImg, this.bwhRatio, this.jpName,
        this.enName, this.koName]

      console.log(throwData)

      instance
        .post('addActorItem', {actor: throwData})
        .then(response => (this.axiosRes = response.data))
        .catch(error => (this.err = error))
    },
    chkForm: function () {
      if(this.jpName != '' && this.enName != '')
        this.btnDisabled = false
      else if(this.jpName == '' || this.enName == '') this.btnDisabled = true
    },
  },

  data: () => ({
    modal: 0,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    err: null,
    axiosRes: null,
    btnLoading: false,
    btnDisabled: true,
    loading: false,
    height: 0,
    actorImg: '',
    bwhRatio: '',
    jpName: '',
    enName: '',
    koName: '',
    rules: {
        required: value => !!value || '필수 입력 항목입니다.',
        counter: value => value.length <= 20 || 'Max 20 characters',
    },
  }),
};
</script>

<style>
.theme--light.v-list{ background: #424242; }
.v-list-item__content{ color: #fff; }
.select { max-width: 220px; }
.v-input__icon .theme--dark.v-icon { color: #9e9e9e !important; }

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input { color: #9e9e9e !important; }
</style>
