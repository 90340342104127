<template>
  <v-parallax
    class="overflow-visible"
    height="500" dark
    :src="palaxImg[imgIndex]">

    <v-row
      justify="center" no-gutters>

      <v-col>
        <div class="py-16">&nbsp;</div>
        <div class="py-10">&nbsp;</div>
        <div class="py-1" v-if="mainTitle=='Welcome!'">&nbsp;</div>
      </v-col>

      <v-col
        class="mt-2"
        cols="12">
        <v-icon light class="white--text"
          @click = "chgDrawer()">mdi-menu-open</v-icon>

      </v-col>

      <v-col
        class="text-center"
        cols="12">
        <h6 class="text-h3 font-weight-bold mt-16 mb-4">
          {{mainTitle}}
        </h6>
        <h4 class="subheading font-weight-thin">
          {{subTitle}}
        </h4>

      </v-col>

      <v-col
        class="mt-4 text-center"
        cols="12">
        <router-view
          ref="callChild"
          @setMainTitle="setMainTitle"
          @setSubTitle="setSubTitle"
          @setSnak="setSnak"
          :key="$route.fullPath">
        </router-view>
      </v-col>

    </v-row>

    <v-snackbar
      v-model="snackbar"
      color="red accent-2"
      :timeout="timeout">

      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white" text v-bind="attrs"
          @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-parallax>
</template>

<script>
export default {
  name: 'AccountView',

  components: {

  },

  props: {
    comTitle: String,
  },

  created: function () {
    this.imgIndex = this.getRandomIntInclusive(0, 3)
  },

  methods: {
    getRandomIntInclusive: function (min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
    setMainTitle: function(str) {
      this.mainTitle = str
    },
    setSubTitle: function(str) {
      this.subTitle = str
    },
    setSnak: function(str) {
      this.text = str
      this.snackbar = true
    },
  },

  data: () => ({
    appTitle : '',
    mainTitle: '',
    subTitle: '',
    snackbar: false,
    text: 'My timeout is set to 2000.',
    imgIndex: 0,
    palaxImg: [
      require('../assets/images/parallax1.png'),
      require('../assets/images/parallax2.png'),
      require('../assets/images/parallax3.jpg'),
      require('../assets/images/parallax4.jpg')
    ],
    timeout: 3000,
  }),
};
</script>

<style>
 .v-parallax > div{border-radius: 24px !important;}
</style>
