<template>

  <v-row
    align-content="center">
    <v-col cols="2"
      v-if="chapterList == null">
      <v-list
        class="rounded-lg pb-0 pt-4" dense>
        <v-list-item-group>
          <v-list-item>
            <v-skeleton-loader
             class="mx-auto"
             width="100" height="136"
             type="image">
            </v-skeleton-loader>
          </v-list-item>
          <v-list-item>
            <v-skeleton-loader
             class="mx-auto"
             width="100"
             type="text">
            </v-skeleton-loader>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col
      class=""
      v-else-if="chapterList != null"
      cols="2" align="center">
      <v-list
        class="rounded-lg pb-0 pt-4"
        dense>
        <v-list-item-group
          class="pb-4">
          <v-list-item>
            <v-img
              class="white--text align-end"
              :src="comicThumb"
              lazy-src="../assets/images/Webtoon.png"
              height="140" contain position="center">

              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center" justify="center">

                  <v-progress-circular
                    indeterminate
                    color="primaryText">
                  </v-progress-circular>
                </v-row>
              </template>

            </v-img>
           </v-list-item>
          <v-list-item
            v-for="(item, i) in chapterList" :key="i"
            @click="getWebToonPageList(i)">
            <v-list-item-content>
              <v-list-item-title
                class="primaryText--text"
                v-text="item.subtitle"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col
      v-if="pageList == null"
      cols="10">
      <v-skeleton-loader
       class="mx-auto rounded-lg"
       type="image">
      </v-skeleton-loader>
    </v-col>
    <v-col
      class=""
      v-if="pageList != null"
      cols="10" align="center">
      <v-img
        class="white--text align-end"
        v-for="(item, i) in pageList" :key="i"
        :src="item.comicImg"
        lazy-src="../assets/images/Webtoon.png"
        contain position="center">

        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center" justify="center">

            <v-progress-circular
              indeterminate
              color="primaryText">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>

</template>

<script>
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export default {
  name: 'WebtoonDetail',

  components: {

  },

  props: {
    comic: String,
    comicThumb: String,
  },

  created: function () {
    this.$emit('setAppTitle', this.appTitle)
    this.getWebToonDetailList()
  },

  mounted: function () {

  },

  methods: {
    getWebToonDetailList: function (){
      instance
        .post('comicDetailList', {fk: this.comic})
        .then(response => ( this.chapterList = response.data ))
        .catch(error => (this.err = error))
    },
    getWebToonPageList: function (ch){
      this.pageList = null
      instance
        .post('comicPageList', {fk: this.comic, chapter: ch})
        .then(response => ( this.pageList = response.data ))
        .catch(error => (this.err = error))
    },
    chgDrawer: function() {
      this.$emit('chgDrawer')
    },
  },

  data: () => ({
    appTitle: 'WebtoonDetail',
    chapterList: null,
    pageList: null,
    err: null,
  }),
};
</script>
